// 自定义样式

@media screen and (max-width: 800px) {
  // 自定义颜色
  :root {
    --main-color: #395beb; // 主要前景色
    --font-text-color: #2c2c35; // 主要的文字颜色
  }

  //重写原生样式
  html {
    font-size: 20px; // 1rem = 20px
    font-weight: 400;
    color: var(--font-text-color); // 主要的文字颜色
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--font-text-color);
    font-weight: 400;
    margin: 0;
  }

  li {
    list-style: none;
  }

  // 自定义样式
  .header {
    height: 32rem;
    background: linear-gradient(
      180deg,
      rgba(35, 35, 85, 1) 0%,
      rgba(1, 1, 6, 1) 100%
    );
  }

  .footerBar {
    height: 10rem;
    background: linear-gradient(
      to left,
      rgba(102, 57, 235, 1) 0%,
      rgba(57, 58, 235, 1) 100%
    );
  }

  .footer {
    height: 18rem;
    background: linear-gradient(
      180deg,
      rgba(14, 14, 27, 1) 0%,
      rgba(1, 1, 6, 1) 100%
    );
  }

  .contact {
    background-color: #f7f8ff;
  }

  .logo-main {
    height: 2rem;
    line-height: 2rem;
    svg {
      height: 1.8rem;
      path {
        fill: var(--main-color);
      }
    }
  }

  .logo-white {
    height: 2rem;
    line-height: 2rem;
    svg {
      height: 1.8rem;
      path {
        fill: #fff;
      }
    }
  }
}
