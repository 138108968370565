.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.3rem;
  line-height: 3.3rem;
  button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 0.3rem 0.75rem;
    border-radius: 3px;
  }

  svg {
    & > path:nth-child(3) {
      fill: var(--main-color);
    }
  }
}

.menuHidden {
  display: none;
}
