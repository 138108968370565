.content {
  margin: 0;
  height: 10rem;
  line-height: 10rem;
  color: #fff;
  text-align: center;
  background: #364d79;
}

.level2 {
  text-align: center;
}

.buttonList {
  padding-bottom: 2rem;
}

.carousel {
  overflow: hidden;
  height: 10rem;
  img {
    width: 100%;
    height: 10rem;
  }
}

.ant-radio-button-wrapper {
  width: 16rem;
}

.ul {
  display: inline-flex;
  justify-content: left;
  max-width: 100%;
  overflow: scroll;
  padding: 0;
  li {
    button {
      width: 100px;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

.checked {
  color: var(--main-color);
}
