
.box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3rem;
  div {
    width: 50%;
  }
  img {
    width: 100%;
  }
}