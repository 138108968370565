.col {
  img {
    width: 100%;
    max-height: 11.25rem;
  }
  &:hover {
    .mask {
      top: -100%;
    }
    .mask2 {
      top: -100%;
    }
  }
}

.box {
  position: relative;
  overflow: hidden;
  &:hover {
    .svg {
      opacity: 0;
    }
    .text {
      opacity: 1;
    }
  }
}


.svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 1;
  transition: opacity 0.5s;
}

.text {
  position: absolute;
  width: 80%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: opacity 0.5s;
  color: #fff;
}


.title {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1.2rem;
  color: #ffffff;
}

