.item {
  display: flex;
}

.icon {
  display: block;
  width: 2.8rem;
  height: 2.8rem;
  margin-right:0.6rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text {
  display: inline-block;
  width: 80%;
  text-align: left;
  span {
    display: block;
  }
}

.title {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}

.subtitle {
  font-size: 0.8rem;
}
