
.text {
  margin: 6.5rem auto 2rem auto;
  color: var(--font-text-color)
}

.title {
  font-size: 1.6rem;
  font-weight: 700;
}

.subtitle {
  font-size: 0.8rem;
  font-weight: 700;
}