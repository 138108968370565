
.list {
  margin-bottom: -1.6rem;
}

.item {
  display: flex;
  margin-bottom:1.6rem;
}

.icon {
  display: block;
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1.2rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text {
  display: inline-block;
  width: 80%;
  text-align: left;
}

.title {
  font-size: 1.2rem;
  padding-bottom: 0.8rem;
}

.subtitle {
  font-size: 0.8rem;
}
