.col {
  img {
    width: 100%;
    max-height: 11.25rem;
  }
  &:hover {
    .mask {
      top: -100%;
    }
    .mask2 {
      top: -100%;
    }
  }
}

.box {
  position: relative;
  overflow: hidden;
  margin: 0.4rem;
  border-radius: 3px;
  &:hover {
    .svg {
      opacity: 0;
    }
    .text {
      opacity: 1;
    }
  }
}


.svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 1;
  transition: opacity 0.5s;
}

.text {
  position: absolute;
  width: 80%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: opacity 0.5s;
  color: #fff;
}

.title {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1.2rem;
  color: #ffffff;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  transition: top 0.4s;
  &.maskType1 {
    background-color: rgba(57, 91, 235, 0.8);
  }
  &.maskType2 {
    background-color: rgba(14, 14, 27, 0.7);
  }
}
