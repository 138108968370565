.footerBar {
  display: block;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
}

.text {
  font-size: 1.4rem;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding: 1.6rem 0 1.4rem 0;
}

.button {
  font-size: 0.9rem;
  font-weight: 400;
  color: #ffffff;
  padding: 0.7rem 2rem;
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 3px;
  cursor: pointer;
}
