.footer {
  height: 18rem;
}

.content {
  height: 14.3rem;
  display: flex;
  justify-content: center;
  margin: 0 0.6rem;
}

.left {
  color: rgba(255, 255, 255, 0.5);
}

.QRCode {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  background-color: #d8d8d8;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
  img {
    width: 100%;
    height: 100%;
  }
}


.copyright {
  font-size: 0.7rem;
  border-top: 1px solid rgba(225, 221, 221, 0.3);
  padding-top: 0.6rem;
  color: rgba(255, 255, 255, 0.5);
  a {
    color: inherit;
  }
}
