.main {
  height: 26rem;
}

.desc {
  text-align: center;
  
  padding-top: 2rem;
  h1 {
    font-size: 2rem;
    color: #fff;
  }
  h2 {
    margin: 0.8rem 0 1.2rem 0;
    font-size: 0.9rem;
    color: #fff;
  }
}

.img {
  margin-top: 1.4rem;
  img {
    width: 9rem;
  }
}
