.process {
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 分割线
.divider {
  position: relative;
  top: 40px;
  z-index: -1;
  width: 80%;
  height: 6px;
  background-color: #f5f5ff;
  margin: 0 auto;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 1.8rem;
  background: linear-gradient(
    to bottom,
    rgba(57, 91, 235, 1) 0%,
    rgba(148, 57, 235, 1) 100%
  );
  box-shadow: 0px 0px 10px 0px rgba(107, 81, 226, 0.5);
}

.text {
  margin-top: 0.8rem;
  font-size: 0.8rem;
  font-weight: 500;
}
