// 覆盖antd的样式

.ant-btn-primary {
  border-color: var(--main-color);
  background-color: var(--main-color);
}

.ant-btn-text {
  color: #fff;
}

// 修改antd的走马灯carousel的面板指示点dots样式
.ant-carousel .slick-dots li {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.4rem;
  button {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 1;
  }
  &.slick-active {
    width: 0.6rem;
    button {
      background: #395beb;
    }
  }
}

.ant-radio-button-wrapper {
  border: 1px solid var(--main-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background-color: var(--main-color);
  &:hover {
    color: #fff;
  }
}

.ant-form-item-explain-error {
  text-align: left;
}

.ant-radio-button-wrapper {
  width: 180px;
  font-size: 18px;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid var(--main-color);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #3352d3;
  background-color: #3352d3;
}

.ant-btn-text:hover, .ant-btn-text:focus {
  color: #fff;
}

@media screen and (min-width: 700px) {
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  
  .ant-radio-group-large .ant-radio-button-wrapper {
    border: 1px solid var(--main-color);
    border-left: none;
    &:first-child {
      border-left: 1px solid var(--main-color);
    }
  }
}

