.main {
  display: flex;
  justify-content: space-between;
  height: 35rem;
}

.desc {
  padding-top: 8rem;
  text-align: left;
  h1 {
    font-size: 2rem;
    color: #fff;
  }
  h2 {
    margin: 0.8rem 0 1.2rem 0;
    font-size: 0.9rem;
    color: #fff;
  }
  button {
    height: 2.8rem;
    width: 8rem;
    font-size: 0.9rem;
    
  }
}

.img {
  padding-top: 5.8rem;
}
