.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  line-height: 3rem;
  div {
    height: 3rem;
    line-height: 3rem;
    div:not(:last-child) {
      button:hover {
        color: #395beb;
      }
    }
    :global(.ant-space-item):last-child  {
      margin-left: 0.75rem;
    }
  }
  svg {
    width: 6.5rem;
    height: 3rem;
    & > path:nth-child(3) {
      fill: var(--main-color);
    }
  }
}

.menuHidden {
  display: none;
}
