.content {
  margin: 0;
  height: 23rem;
  color: #fff;
  line-height: 23rem;
  text-align: center;
  background: #364d79;
}

.carousel {
  width: 70%;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  img {
    width: 100%;
    height: 23rem;
  }
}

.ul {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 1.6rem;
  li {
    padding: 0 1rem;
    button {
      width: 100px;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

.checked {
  color: var(--main-color);
}
