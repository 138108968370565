.footer {
  height: 19.25rem;
}

.content {
  height: 16.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  padding-top: 2rem;
  div {
    svg {
      width: 6.5rem;
      height: 3.2rem;
      margin-top: 0.7rem;
      margin-bottom: 0.6rem;
      & > path:nth-child(3) {
        fill: var(--main-color);
      }
    }
  }
}

.info {
  display: flex;
  color: rgba(255, 255, 255, 0.5);
}

.QRCode {
  width: 4.6rem;
  height: 4.6rem;
  margin: 0;
  border-radius: 3px;
  overflow: hidden;
  background-color: #d8d8d8;
  img {
    width: 100%;
    height: 100%;
  }
}

.desc {
  display: flex;
  height: 4.6rem;
  width: 10rem;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  margin-left: 1rem;
}

.menu {
  font-size: 0.7rem;
  font-weight: 400;
  text-align: right;
  margin: 0;
  margin-right: -0.75rem;
  li {
    margin: 1rem 0;
    a {
      color: #ffffff;
    }
    button:hover {
      color: var(--main-color);
    }
  }
}

.copyright {
  font-size: 0.7rem;
  border-top: 1px solid rgba(225, 221, 221, 0.3);
  padding-top: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
  a {
    color: inherit;
  }
}
