.contactUs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.title {
  width: 100%;
  text-align: left;
  h1 {
    font-size: 2rem;
    font-weight: 500;
  }
  h2 {
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0.8rem 0;
  }
}

.form {
  width: 100%;
}

.description {
  width: 100%;
  // padding-left: 4rem;
  text-align: left;
  h1 {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    div {
      div {
        svg {
          margin-right: 0.6rem;
        }
      }
    }
  }
  h2 {
    font-size: 0.7rem;
    font-weight: 400;
    padding-left: 2.4rem;
  }
  ul {
    padding-left: 2.4rem;
    li {
      padding-top: 0.2rem;
    }
  }
}
