.ant-form-item-control-input-content {
  input {
    height: 2.4rem;
    line-height: 2.4rem;
  }
  button {
    height: 2.2rem;
  }
}

// 重写antd RadioGroup样式
@media screen and (max-width: 800px) {
  .ant-radio-button-wrapper {
    width: 16rem;
    border-top: 1px solid var(--main-color);;
    border-bottom: none;
    border-left: 1px solid var(--main-color);
    border-right: 1px solid var(--main-color);
  }
  
  .ant-radio-button-wrapper:first-child {
    border: none;
    border-radius: 0;
    border-top: 1px solid var(--main-color);
    border-left: 1px solid var(--main-color);
    border-right: 1px solid var(--main-color);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .ant-radio-button-wrapper:last-child {
    border: 1px solid var(--main-color);
    border-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
  }
  
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
}
