
.box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3rem;
  img {
    max-width: 9.3rem;
  }
}