
.text {
  margin: 3rem auto 2rem auto;
}

.title {
  font-size: 1.6rem;
  font-weight: 700;
}

.subtitle {
  font-size: 0.8rem;
  font-weight: 700;
}